<template>
  <div class="form-group">
    <div v-if="label" class="form-group__label-wrapper" :class="{ required }">
      <label class="form-group__label">{{ label }}</label>
    </div>
    <date-picker
        ref="datePickerRef"
        :class="{error: withError && !datetimeValue}"
        :value="datetimeValue"
        :format="format"
        :mode="mode"
        is24hr
        :min-date="minDatetime"
        :max-date="maxDatetime"
        :disabled-dates="disabledDates"
        :masks="{input: ['DD.MM.YYYY']}"
        :model-config="modelConfig"
        :first-day-of-week="2"
        :disabled="disabled"
        :attributes="attributes"
        :locale="language"
        :popover="{ visibility: popoverVisibility }"
        @input="onInput"
    >
      <template v-slot="{ inputValue, inputEvents, hidePopover }">
        <div class="app-input">
          <input
            class="px-2 py-1 border w-100"
            :class="inputRoundedSize === 'lg' ? 'rounded-xxl' : 'rounded'"
            :value="inputValue"
            v-on="inputEvents"
            :disabled="disabled"
            @focus="onFocus(hidePopover)"
            @blur="onBlur"
          />
          <div
            v-if="allowClear && datetimeValue"
            class="clear-btn cursor-pointer"
            :class="{'clear-btn-with-icon': withIcon}"
            @click="onInput(null)"
          >
            <span class="fas fa-times h4 m-0" :class="clearColor"></span>
          </div>
          <div v-if="withIcon" class="app-input__append">
            <span class="input-group-text">
              <span class="far fa-calendar-alt"></span>
            </span>
          </div>
        </div>
      </template>
    </date-picker>
    <label :class="{ 'active': inputActive || !!value }">{{ placeholder }}</label>
    <span v-if="helpMessage && !(withError && !datetimeValue)" class="form-text text-muted help-message">
      {{ helpMessage }}
    </span>
    <p v-if="withError && !datetimeValue" class="form-text text-danger error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import i18n from '@/core/plugins/vue-i18n';

export default {
  components: {
    DatePicker
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    mode: {
      type: String,
      default: 'date'
    },
    format: {
      default: 'd.L.y'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withError: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: false
    },
    blur: {
      type: Boolean,
      default: false
    },
    clearColor: {
      type: String,
      default: 'text-danger'
    },
    errorMessage: {
      type: String,
      default: i18n.t("error.field_is_required")
    },
    helpMessage: {
      type: String,
      default: ''
    },
    minDatetime: {
      default: null
    },
    maxDatetime: {
      default: null
    },
    disabledDates: {
      default: null
    },
    availableDates: {
      default: null
    },
    modelConfig: {
      default: null
    },
    attributes: {
      default: () => []
    },
    popoverVisibility: {
      default: 'hover-focus'    // hover-focus, hover, focus, click, visible, hidden
    },
    inputRoundedSize: {
      type: String,
      default: 'sm'
    },
  },
  data: () => ({
    datetimeValue: null,
    inputActive: false,
    f: null
  }),
  computed: {
    language() {
      let lang = localStorage.getItem('language');
      if (lang === 'ge') lang = 'ka';
      return lang || 'en';
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.datetimeValue = v;
      },
    },
    blur(val) {
      if (val) {
        if (this.f) this.f();
      }
    }
  },
  methods: {
    onInput(value) {
      this.datetimeValue = value;
      this.$emit('change', value);
    },
    onFocus(f) {
      this.inputActive = true;
      // if (this.value) {
      //   this.$emit('change', null);
      // }
      this.$emit('focus');
      this.f = f;
    },
    onBlur() {
      this.inputActive = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  position: relative;

  & .vdatetime-input {
    display: block;
    padding: 0.65rem 1rem;
    width: 100%;
    line-height: 1.5;
    color: #3F4254;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
  }

  & .clear-btn {
    position: absolute;
    right: 15px;
    top: 20px;
  }

  & .clear-btn-with-icon {
    right: 50px;
  }
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }

  &.password {
    display: flex;
    align-items: center;
  }
}

.help-message {
  position: absolute;
  bottom: -20px;
  left: 0;
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
}

.app-input {
  position: relative;
  height: 60px;

  &__append {
    position: absolute;
    bottom: 1px;
    right: 1px;

    & .input-group-text {
      height: 36px;
    }
  }
}

.app-input {
  input {
    height: 60px;
    padding: 16px 16px 0 !important;
    font-size: 16px;
    border: 1px solid #6E7173 !important;
  }
}

label {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: transform 0.2s, font-size 0.2s, color 0.2s;

  &.active {
    transform: translate(-3px, -14px);
    font-size: 12px;
    color: #333;
  }

  &.error {
    color: #E90900;
  }
}

.error::v-deep .app-input input {
  border-color: #f64e60 !important;
}

::placeholder {
  color: #B5B5C3;
  opacity: 1;
}
</style>