export const formValidation = {
  methods: {
    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let isEmailValid = regex.test(email);

      return !email
        ? 'placeholder.enter_email'
        : !isEmailValid
          ? 'error.invalid_email'
          : '';
    },
    validatePhoneNumber(phoneNumber) {
      // const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      const regex = /^\d{8,12}$/;
      
      return regex.test(phoneNumber);
    }
  }
}