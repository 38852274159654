<template>
  <div class="step-three-wrapper mt-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="font-weight-bolder">
        <div class="fs-md">{{ $t('label.check_in') }}</div>
        <div class="fs-sm">{{ dateToString(data.income_date) }}</div>
      </div>
      <div class="font-weight-boldest h3">—</div>
      <div class="font-weight-bolder text-right">
        <div class="fs-md">{{ $t('label.check_out') }}</div>
        <div class="fs-sm">{{ dateToString(data.exit_date) }}</div>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-between align-items-center">
      <div class="fs-md font-weight-boldest">{{ $t('label.guests') }}</div>
      <div class="fs-sm font-weight-bolder">
        <span>{{ $tc('label.adults_plural', adultsCount) }}</span>,
        <span>{{ $tc('label.children_plural', childrenCount) }}</span>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-between align-items-center">
      <div class="fs-md font-weight-boldest">{{ $t('label.person') }}</div>
      <div class="font-weight-bolder">{{ fullName }}</div>
    </div>
    <hr>
    <div class="fs-lg font-weight-bolder text-uppercase my-3">{{ $t('label.leave_something_for_host') }}</div>
    <floating-textarea-form-group
      :class="{filled: form.comment}"
      :placeholder="$t('label.your_wishes')"
      :value="form.comment"
      :max-length="commentMaxLength"
      input-rounded-size="lg"
      :with-label="false"
      :rows="2"
      :max-rows="10"
      @input="form.comment = $event"
    />
    <div class="w-100 fs-xs text-right mt-1" :class="{'text-danger': form.comment.length === commentMaxLength}">
      <span>{{ form.comment.length }}</span>
      <span class="mx-1">/</span>
      <span>{{ commentMaxLength }}</span>
    </div>

    <b-row class="mt-7 mb-5">
      <b-col cols="4" lg="4">
        <button
          class="btn btn-outline-dark btn-hover-light-dark fs-xl font-weight-bold text-uppercase w-100 mb-3 mb-lg-0 py-4"
          @click="onBack"
        >
          {{ $t('btn.back') }}
        </button>
      </b-col>
      <b-col cols="8" lg="8">
        <button
          class="btn btn-green fs-xl font-weight-bold text-uppercase w-100 py-4"
          @click="onSubmit"
        >
          {{ $t('btn.submit') }}
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { dateMixin } from '@/mixins/dateMixin';
import FloatingTextareaFormGroup from '../../../components/elements/form-groups/FloatingTextareaFormGroup.vue';

export default {
  components: {
    FloatingTextareaFormGroup
  },
  mixins: [dateMixin],
  props: {
    data: {type: Object, default: () => {}}
  },
  data: () => ({
    commentMaxLength: 500,
    form: {
      comment: ''
    }
  }),
  computed: {
    fullName() {
      return this.data?.fName + ' ' + this.data?.lName;
    },
    adultsCount() {
      return this.data?.guests?.adults_count || 1;
    },
    childrenCount() {
      return this.data?.guests?.children_count || 0;
    }
  },
  watch: {
    form: {
      immediate: true,
      handler(data) {
        this.$emit('formChange', data);
      },
      deep: true
    }
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
    onSubmit() {
      this.$emit('submit');
    },
  }
}
</script>

<style lang="scss" scoped>
.step-three-wrapper::v-deep {
  textarea {
    color: #222 !important;
    border-color: #fff !important;
    border-width: 10px 0 !important;
    outline: 1px solid #6E7173;
  }

	.active {
		font-size: 14px !important;
	}

  .filled {
    label {
      color: #222 !important;
    }
  }
}
</style>