<template>
  <div class="fv-row position-relative" :class="size">
    <Multiselect
      ref="multiselect"
      class="app-multiselect"
      :class="{active: !!value, 'rounded-xxl': inputRoundedSize === 'lg'}"
      :value="value"
      :options="options"
      :multiple="multiple"
      :label="itemLabel"
      :group-label="groupLabel"
      :group-values="groupValues"
      :group-select="groupSelect"
      track-by="id"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      deselect-label=""
      :searchable="searchable"
      :hideSelected="false"
      :disabled="disabled"
      placeholder=""
      :close-on-select="!multiple"
      :allow-empty="allowEmpty"
      :loading="isLoading"
      @open="inputActive = true"
      @close="inputActive = false"
      @select="$emit('select', $event)"
      @remove="$emit('remove', $event)"
    >
      <span slot="noResult">{{ noOptionsText }}</span>
      <template slot="clear">
        <div
          v-if="withClearBtn && !disabled && value"
          class="multiselect__clear"
          @mousedown.prevent.stop="$emit('remove', $event)"
        ></div>
      </template>
      <template #noOptions>
        <span>{{ noOptionsText }}</span>
      </template>
      <template v-slot:singleLabel="{ option }">
        <slot name="singleLabel" :option="option"></slot>
      </template>
    </Multiselect>


    <label
      class="mb-0"
      :class="{ 'active': inputActive || !!value, 'error': error, 'text-green': !!value && !error }"
    >
      {{ placeholder }}
    </label>
    <div v-if="!!error" class="text-danger mt-1">
      <inline-svg src="/media/svg/icons/General/Attention.svg"/>
      {{ $t(error) }}
    </div>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    itemLabel: {
      type: String,
      default: 'label'
    },
    groupLabel: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    withClearBtn: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    noOptionsText: {
      type: String,
      default: i18n.t('description.list_is_empty')
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    groupSelect: {
      type: Boolean,
      default: false
    },
    groupValues: {
      type: String,
      default: ''
    },
    helpMsg: {
      type: String,
      default: ''
    },
    inputRoundedSize: {
      type: String,
      default: 'sm'
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data: () => ({
    inputActive: false,
    isLoading: false,
  }),
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.sm {
  label {
    top: 10px;
    left: 12px;
    font-size: 12px;

    &.active {
      display: none;
    }
  }

  .app-multiselect::v-deep {
   &.multiselect {
      min-height: 36px;
    }

    .multiselect__select {
      height: 36px;
      top: 3px;
      right: -7px;
    }

    .multiselect__tags {
      padding: 8px 30px 0 10px;
      min-height: 36px;
    }

    .multiselect__single {
      font-size: 12px;
    }
  }
}

label {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: transform 0.2s, font-size 0.2s, color 0.2s;

  &.active {
    transform: translate(-3px, -14px);
    font-size: 12px;
    color: #333;
  }

  &.error {
    color: #E90900;
  }
}


.form-group {
  position: relative;

  &.error {
    .app-multiselect::v-deep {
      .multiselect__tags {
        border-color: #f64e60;
      }
    }
  }

  &.with-groups {
    .app-multiselect::v-deep {
      .multiselect__option {
        padding: 5px 12px 5px 40px;
      }
    }
  }
}

.form-group__link-wrapper {
  position: absolute;
  z-index: 5;
  padding: 0 5px;
  top: -10px;
  left: 9px;
  background: white;
  font-size: 10px;
}

.form-group__label-wrapper.required {
  position: relative;
  display: inline-block;

  :after {
    content: "*";
    position: absolute;
    color: #f64e60;
    font-weight: bold;
    right: -10px;
  }
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
  font-size: 0.9rem;
  color: #f64e60;
}

.app-multiselect::v-deep {
  &.active {
    .multiselect__tags {
      border-color: #008E49;
    }
  }

  &.multiselect {
    min-height: 60px;
    border-radius: 0.42rem;
  }

  .multiselect__select {
    height: 60px;
  }

  .multiselect__content-wrapper {
    overflow-x: hidden;

    .multiselect__content {
      width: 100%;
    }
  }

  .multiselect__clear {
    position: absolute;
    right: 41px;
    height: 40px;
    width: 40px;
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  .multiselect__clear:before {
    transform: rotate(45deg);
  }

  .multiselect__clear:after {
    transform: rotate(-45deg);
  }

  .multiselect__clear:after,
  .multiselect__clear:before {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 16px;
    background: #aaa;
    top: 12px;
    right: 4px;
  }

  &.multiselect--active {
    z-index: unset;

    .multiselect__tags {
      border-color: #9cb8ff;
    }
  }

  .multiselect__tags {
    padding: 27px 40px 0 10px;
    min-height: 60px;
    border: 1px solid #6E7173;
    border-radius: 0.42rem;
  }

  .multiselect__option {
    padding: 5px 12px 5px 13px;
    min-height: 24px;
    //width: 175px;
    line-height: 14px;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: black;
    background-color: white;

    &:hover {
      color: black;
      background-color: #f2f3f8;
    }
  }

  .multiselect__option--group {
    padding: 7px 12px 7px 13px !important;
  }

  .multiselect__single {
    color: #6E7173;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multiselect__tag {
    margin-top: 2px;
    margin-bottom: 0;
    background-color: #efefef;
    color: black;
    font-size: 1rem;
  }

  .multiselect__tag-icon {
    &:after {
      color: black;
    }
    &:focus,
    &:hover {
      color: white;
      background-color: #f64e60;
      &:after {
        color: white;
      }
    }
  }

  .multiselect__placeholder {
    margin-bottom: 0;
    padding-left: 5px;
    color: black;
    opacity: 0.4;
    font-size: 16px;
    font-weight: 500;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .multiselect__input {
    padding-top: 2px;
    padding-left: 5px;
    color: black;
    opacity: 0.5;
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .app-multiselect::v-deep {
    &.multiselect {
      min-height: 45px;
    }

    .multiselect__select {
      height: 45px;
    }

    .multiselect__tags {
      padding: 20px 40px 0 10px;
      min-height: 45px;
      font-size: 14px;
    }

    .multiselect__single {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  label {
    top: 13px;
    font-size: 14px;

    &.active {
      transform: translate(-3px, -10px);
      font-size: 11px;
    }
  }
}
</style>